<template>
  <a-modal :visible="visible" :wrap-class-name="$style.modalSync" :closable="false">
    <p class="mt-2">
      Export Excel
    </p>
    <a-range-picker
      class="date-container w-100"
      :ranges="ranges"
      :format="dateFormat"
      :value="[startDate, endDate]"
      :placeholder="[
        $t('dashboardContent.start_date'),
        $t('dashboardContent.end_date'),
      ]"
      :disabled-date="disabledDate"
      :disabled="isLoading"
      allow-clear
      @change="onChangeDate"
    >
      <DateIcon slot="suffixIcon" style="color: #999999" />
    </a-range-picker>
    <template #footer>
      <a-button key="back" :disabled="isLoading" class="mr-2" @click="typeof back !== 'undefined' && back(), resetData(), onClose()">
        Cancel
      </a-button>
      <a-button key="submit" :disabled="isLoading || !startDate" type="primary" @click="onSubmitModal()">
        Export Excel
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import getQueryParam from '@/utils/getQueryParam'
import DateIcon from '@/components/Icons/DateV2.vue'

export default {
  name: 'ModalExportExcel',
  components: {
    DateIcon,
  },
  mixins: [getQueryParam],
  props: {
    visible: Boolean,
    back: Function,
    onClose: Function,
    isLoading: Boolean,
  },
  data() {
    return {
      ranges: {
        'Hari ini': [this.$moment(), this.$moment()],
        'Bulan ini': [this.$moment().startOf('month'), this.$moment().endOf('month')],
        'Tahun ini': [this.$moment().startOf('year'), this.$moment().endOf('year')],
        '7 Hari Terakhir': [this.$moment().subtract(7, 'days'), this.$moment()],
        '30 Hari Terakhir': [this.$moment().subtract(1, 'month'), this.$moment()],
        '1 Tahun Terakhir': [this.$moment().subtract(1, 'year'), this.$moment()],
      },
      dateFormat: 'DD MMMM YYYY',
      startDate: '',
      endDate: '',
    }
  },
  computed: {
    integration() {
      return this.$store.state.integration
    },
  },
  methods: {
    to(url) {
      const route = typeof url === 'object' ? url : { path: url }
      route.query = {
        ...this.to.query,
        ...this.getQueryParam({ ...this.$route.query }),
        ...url.params?.query,
      }
      
      return route
    },
    disabledDate(current) {
      return current && current > this.$moment().endOf('day')
    },
    onChangeDate(date) {
      this.startDate = date[0]
        ? this.$moment(date[0]).format(this.formatDate)
        : ''
      this.endDate = date[1]
        ? this.$moment(date[1]).format(this.formatDate)
        : ''
    },
    onSubmitModal() {
      const selectedDate = {
        startDate: this.startDate,
        endDate: this.endDate,
      }
      this.$emit('handleSubmitModal', selectedDate)
      this.resetData()
    },
    resetData() {
      this.startDate = ''
      this.endDate = ''
    },
  },
}
</script>

<style lang="scss" module>
// @import './style.module.scss';
</style>